import axios from "axios";
import { GetLogin } from "./StorageHandler";

export function CreateAxiosInterceptors() {
    axios.interceptors.request.eject();
    axios.interceptors.response.eject();

    axios.interceptors.request.use(function (config) {
        var apiKey = GetLogin();
        if (apiKey) { config.headers.set('X-Api-Key', apiKey); }
        return config;
    });
}