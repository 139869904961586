import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { JobTypes } from "../Consts/JobData";
import JobStatusBar from "./Universal/JobStatusBar";
import { useNavigate } from "react-router-dom";
import moment from 'moment';

function JobCard({ job }) {
    const Navigate = useNavigate();

    return (
        <>
            <div className="w-100 border-top border-bottom shadow py-2 px-2 mt-3 position-relative bg-white">
                <h3 className="row">
                    <div className="col">
                        Job: {job?.id}
                    </div>
                    <div className="col">
                        <JobStatusBar job={job} />
                    </div>
                </h3>
                <h6>Site: {job?.site?.customer?.name} - {job?.site?.name}</h6>
                <h6>Type: {JobTypes[job?.jobType]}</h6>
                <h6>Asset: {job?.asset?.name ?? "Unassigned"}</h6>
                {job?.jobType != 0 && <h6>Tipping At: {job?.plant?.name}</h6>}
                <h6>Est. Departure: {moment(job?.departure).format("DD/MM/YYYY HH:mm")}</h6>
                <button className="btn btn-primary w-100 py-2 stretched-link" onClick={() => Navigate(`/job/${job?.id}`)}>More&nbsp;&nbsp;<FontAwesomeIcon icon="up-right-from-square" /></button>
            </div>
        </>
    );
}

export default JobCard;