import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Header from "./Universal/Header";
import LoginPage from "../Pages/Login";
import { useEffect, useState } from "react";
import JobsList from "../Pages/Jobs/JobsList";
import { WsContext, DriverContext, JobsContext } from "../Components/Contexts/Main";
import axios from "axios";
import { URLS } from "../Consts/URLS";
import { WebSocketHandlers_ON_EVENTS } from "../Utils/WebSocketHandlers";
import * as signalR from '@microsoft/signalr';
import { GetDriver, GetLogin, StoreDriver } from "../Utils/StorageHandler";
import JobPage from "../Pages/Jobs/JobPage";
import JobDropStart from "../Pages/Jobs/JobDropStart";
import JobCollectStart from "../Pages/Jobs/JobCollectStart";
import { CreateAxiosInterceptors } from "../Utils/Setup";
import JobExchangeStart from "../Pages/Jobs/JobExchangeStart";
import CollectionCheckin from "../Pages/Jobs/ActiveJobPages/CollectionCheckin";
import CompleteDropPage from "../Pages/Jobs/ActiveJobPages/CompleteDropPage";
import CompleteCollectionPage from "../Pages/Jobs/ActiveJobPages/CompleteCollectionPage";
import CollectionADCheckin from "../Pages/Jobs/ActiveJobPages/CollectionADCheckin";
import ExchangeCheckin from "../Pages/Jobs/ActiveJobPages/ExchangeCheckin";
import ExchangeADCheckin from "../Pages/Jobs/ActiveJobPages/ExchangeADCheckin";
import CompleteExchangePage from "../Pages/Jobs/ActiveJobPages/CompleteExchangePage";
import JobAbortPage from "../Pages/Jobs/ActiveJobPages/JobAbortPage";

function Routing() {
    const [driver, setDriver] = useState(null);
    const [jobs, setJobs] = useState(null);

    let location = useLocation();
    const Navigate = useNavigate();

    function InitJobGet(_driver) {
        axios.get(URLS.JOBS_GET + "/" + _driver?.code, { headers: { "X-Api-Key": GetLogin() } }).then((response) => {
            if (!response.data.success) { throw new Error("Failed to get inital driver jobs"); }

            setJobs([...response.data.data]);
        }).catch(err => console.error(err))
    }

    useEffect(() => {
        var _driver = GetDriver();
        if (_driver != null && _driver != undefined) {
            setDriver(_driver);
            CreateAxiosInterceptors();
        }

        if (location.pathname != "/" && _driver == null) { Navigate("/"); }
        if (location.pathname == "/jobs") { InitJobGet(_driver); }
    }, [location]);

    //#region Websocket Setup

    const connection = new signalR.HubConnectionBuilder()
        .withUrl(URLS.WS)
        .withAutomaticReconnect()
        .configureLogging(signalR.LogLevel.Information)
        .build();

    async function start() {
        try {
            await connection.start();
            console.log("SignalR Connected.");
        } catch (err) {
            console.log(err);
            setTimeout(start, 5000);
        }
    };

    connection.onclose(async () => { await start(); });

    for (const [k, v] of Object.entries(WebSocketHandlers_ON_EVENTS)) { connection.on(k, v); } // Create All Listening Events

    connection.on("JobsUpdate", function JobsUpdate(_jobs, _driver) { setJobs(_jobs); setDriver(_driver); StoreDriver(_driver); });

    start();

    //#endregion

    return (
        <>
            <DriverContext.Provider value={{ driver: driver, setDriver: setDriver }}>
                <WsContext.Provider value={connection}>
                    <JobsContext.Provider value={{ jobs: jobs, setJobs: setJobs }}>
                        <Routes>
                            <Route path='/' element={<Header />}>
                                <Route index element={<LoginPage />} />

                                {/* Base Job Pages */}
                                <Route path="jobs" element={<JobsList />} />
                                <Route path="job/:jobId" element={<JobPage />} />

                                {/* Job Starts */}
                                <Route path="job/:jobId/start/drop" element={<JobDropStart />} />
                                <Route path="job/:jobId/start/collect" element={<JobCollectStart />} />
                                <Route path="job/:jobId/start/exchange" element={<JobExchangeStart />} />

                                {/* Active Job Pages */}
                                <Route path="job/:jobId/checkin/collect" element={<CollectionCheckin />} />
                                <Route path="job/:jobId/checkin/collect/ad" element={<CollectionADCheckin />} />
                                <Route path="job/:jobId/checkin/exchange" element={<ExchangeCheckin />} />
                                <Route path="job/:jobId/checkin/exchange/ad" element={<ExchangeADCheckin />} />
                                <Route path="job/:jobId/complete/drop" element={<CompleteDropPage />} />
                                <Route path="job/:jobId/complete/collect" element={<CompleteCollectionPage />} />
                                <Route path="job/:jobId/complete/exchange" element={<CompleteExchangePage />} />

                                <Route path="job/:jobId/abort" element={<JobAbortPage />} />
                            </Route>
                        </Routes>
                    </JobsContext.Provider>
                </WsContext.Provider>
            </DriverContext.Provider>
        </>
    )
}

export default Routing;