import { useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { DriverContext } from "../../Components/Contexts/Main";
import axios from "axios";
import { URLS } from "../../Consts/URLS";
import Loading from "../../Components/Universal/Loading";
import JobHeader from "../../Components/Universal/JobHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function JobDropStart() {
    const { jobId } = useParams();
    const Navigate = useNavigate();
    const { driver, setDriver } = useContext(DriverContext);

    const [loading, setLoading] = useState(true);
    const [job, setJob] = useState(null);

    const [assetName, setAssetName] = useState('');
    const [error, setError] = useState(false);

    useEffect(() => {
        axios.get(URLS.JOB_GET + "/" + jobId).then((response) => {
            if (!response.data.success) { throw new Error("Failed to get a job"); }

            setJob(response.data.data);
        }).catch(err => console.error(err))
            .finally(() => setLoading(false))
    }, []);

    function HandleJobStart(e) {
        e.preventDefault();

        setLoading(true);

        var body = {
            driverId: driver?.id,
            jobId: jobId,
            assetName: assetName
        }

        axios.post(`${URLS.JOBS_DROP}/start`, body).then((response) => {
            if (!response.data.success) {
                setError(response.data.message);
                throw new Error("Failed to start job");
            }

            Navigate("/jobs");
        })
            .catch(err => console.error(err))
            .finally(() => { setLoading(false); });
    }

    return (
        <>
            <Loading loading={loading} />
            {job != null &&
                <>
                    <div className="w-100 d-flex justify-content-center mt-5">
                        <div className="w-90 mt-5">
                            <JobHeader job={job} />

                            <div className="border rounded shadow mt-3 p-3 bg-white">
                                <form>
                                    <label className="ms-1">Asset: </label>
                                    <input className="form-control" required placeholder="Asset Name: (E.g. WF24)" value={assetName} onChange={(e) => setAssetName(e.target.value)}></input>

                                    {error && <p className="my-2 text-danger">{error}</p>}

                                    <button className="btn btn-primary w-100 mt-3" onClick={HandleJobStart} disabled={assetName == ''}>
                                        Start
                                        &nbsp;&nbsp;
                                        <FontAwesomeIcon icon="paper-plane" />
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    );
}

export default JobDropStart;