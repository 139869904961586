import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../../Components/Universal/Loading";
import { DriverContext } from "../../../Components/Contexts/Main";
import axios from "axios";
import { URLS } from "../../../Consts/URLS";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import JobHeader from "../../../Components/Universal/JobHeader";

function CompleteExchangePage() {
    const { jobId } = useParams();
    const Navigate = useNavigate();

    const { driver, setDriver } = useContext(DriverContext);

    const [loading, setLoading] = useState(true);
    const [job, setJob] = useState(null);
    const [plant, setPlant] = useState(null);
    const [plants, setPlants] = useState(null);

    useEffect(() => {
        axios.get(`${URLS.JOB_GET}/${jobId}`).then((response) => {
            if (!response.data.success) { throw new Error("Failed to get a job"); }

            setJob(response.data.data);
        }).catch(err => console.error(err));
    }, []);

    useEffect(() => { if (job != null) { SetupOptions(); } }, [job]);

    function SetupOptions() {
        axios.get(URLS.PLANTS_GET).then((response) => {
            if (!response.data.success) { throw new Error("Failed to retrieve Plants"); }

            var _plants = Array.from([...response.data.data], (d) => { return { value: d?.id, label: d?.name } });
            setPlants(_plants);
            setPlant(job?.plantId);
        }).catch(err => console.error(err)).finally(() => setLoading(false));
    }

    function HandleExchangeComplete(e) {
        e.preventDefault();

        setLoading(true);

        axios.post(`${URLS.JOBS_EXCHANGE}/complete/${jobId}/${plant}`).then((response) => {
            if (!response.data.success) { throw new Error("Failed to complete exchange job"); }

            Navigate("/jobs")
        }).catch(err => console.error(err))
            .finally(() => setLoading(false));
    }

    return (
        <>
            <Loading loading={loading} />

            {job != null &&
                <>
                    <div className="w-100 d-flex justify-content-center mt-5">
                        <div className="w-90 mt-5">
                            <JobHeader job={job} />

                            <div className="border rounded shadow mt-3 p-3 bg-white">
                                <form>
                                    <label className="ms-1">Confirm Tipped At: </label>
                                    <select className="form-select" required value={plant} onChange={(e) => setPlant(e.target.value)}>
                                        <option disabled value={null}>Please Select an AD Plant...</option>

                                        {plants?.map((plant, i) => (
                                            <option key={i} value={plant.value}>{plant?.label}</option>
                                        ))}
                                    </select>

                                    <label className="ms-1 mt-2">Enter Weighbridge Ticket #: </label>
                                    <input className="form-control" placeholder="Ticket Number..." />

                                    <button className="btn btn-success w-100 mt-3" onClick={HandleExchangeComplete}>
                                        Complete
                                        &nbsp;&nbsp;
                                        <FontAwesomeIcon icon="flag-checkered" />
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    );
}

export default CompleteExchangePage;