import { useContext } from "react";
import { DriverContext } from "../Contexts/Main";

function JobStatusBar({ job }) {
    const { driver, setDriver } = useContext(DriverContext);

    function GetStatusData() {
        if (job?.id != driver?.activeJobId) { return { colour: "secondary", name: "Inactive" }; } // In Job Pool
        if (job?.id == driver?.activeJobId) { return { colour: "warning", name: "Active Job" }; } // In Job Pool
    }

    return (
        <>
            <div className={`w-100 d-flex justify-content-center align-items-center rounded text-white mb-2 py-1 bg-${GetStatusData()?.colour}`}>
                <h4 className="mb-0">{GetStatusData()?.name}</h4>
            </div>
        </>
    )
}

export default JobStatusBar;