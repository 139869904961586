import moment from "moment";
import { JobTypes } from "../../Consts/JobData";
import JobStatusBar from "./JobStatusBar";

function JobHeader({ job }) {
    return (
        <>
            <div className="border shadow rounded p-3 bg-white">
                <JobStatusBar job={job} />
                <h3>Job: {job?.id}</h3>
                <h6>Site: {job?.site?.customer?.name} - {job?.site?.name}</h6>
                <h6>Type: {JobTypes[job?.jobType]}</h6>
                <h6>Asset: {job?.asset?.name ?? "Unassigned"}</h6>
                {job?.jobType != 0 && <h6>Tipping At: {job?.plant?.name}</h6>}
                <h6>Est. Departure: {moment(job?.departure).format("DD/MM/YYYY HH:mm")}</h6>
            </div>
        </>
    )
}

export default JobHeader;