import { useContext, useEffect, useState } from "react";
import Loading from "../../../Components/Universal/Loading";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { URLS } from "../../../Consts/URLS";
import JobHeader from "../../../Components/Universal/JobHeader";
import { DriverContext } from "../../../Components/Contexts/Main";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function JobAbortPage() {
    const { jobId } = useParams();
    const Navigate = useNavigate();

    const { driver, setDriver } = useContext(DriverContext);

    const [job, setJob] = useState(null);
    const [loading, setLoading] = useState(true);

    const [reason, setReason] = useState('');

    useEffect(() => {
        axios.get(URLS.JOB_GET + "/" + jobId).then((response) => {
            if (!response.data.success) { throw new Error("Failed to get a job"); }

            setJob(response.data.data);
        }).catch(err => console.error(err))
            .finally(() => setLoading(false));
    }, []);

    function HandleAbort(e) {
        e.preventDefault();

        setLoading(true);

        var body = {
            jobId: jobId,
            driverId: driver.id,
            reason: reason
        }

        axios.post(URLS.JOB_ABORT, body).then((response) => {
            if (!response.data.success) { throw new Error("Failed to submit job abort"); }

            Navigate("/jobs");
        }).catch(err => console.error(err))
            .finally(() => setLoading(false))
    }

    return (
        <>
            <Loading loading={loading} />

            {job != null &&
                <>
                    <div className="w-100 d-flex justify-content-center mt-5">
                        <div className="w-90 mt-5">
                            <JobHeader job={job} />

                            <div className="border rounded shadow mt-3 p-3 bg-white">
                                <form>
                                    <label className="ms-1">Reason for job abort: </label>
                                    <textarea className="form-control" placeholder="Reason..." value={reason} onChange={(e) => setReason(e.target.value)} />

                                    <button type="submit" className="btn btn-danger w-100 mt-3" disabled={reason == ''} onClick={HandleAbort}>
                                        Submit Job Abort
                                        &nbsp;&nbsp;
                                        <FontAwesomeIcon icon="eject" />
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )

}

export default JobAbortPage;