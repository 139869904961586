export const WebSocketHandlers_ON_EVENTS = {
    "RecieveDriverInit": function (driverId, message) {
        console.log({
            driverId: driverId,
            result: message
        });
    }
}

export const WebSocketHandlers_INVOKE_EVENTS = {
    "InitDriverLogin": { name: "InitDriverLogin", data: [] }
}