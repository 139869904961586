import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../Components/Universal/Loading";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { URLS } from "../../Consts/URLS";
import { DriverContext } from "../../Components/Contexts/Main";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import JobHeader from "../../Components/Universal/JobHeader";

const JobStatus = {
    OTHER_ACTIVE: -1,
    INACTIVE: 0,
    ACTIVE_DROP: 1,
    ACTIVE_COLLECTION: 2,
    ACTIVE_COLLECTION_END: 3,
    ACTIVE_COLLECTION_AD: 4,
    ACTIVE_COLLECTION_AD_END: 5,
    ACTIVE_DROP_EXCHANGE: 6,
    ACTIVE_EXCHANGE_END: 7,
    ACTIVE_EXCHANGE_AD: 8,
    ACTIVE_EXCHANGE_AD_END: 9,
}

function JobPage() {
    const { jobId } = useParams();

    const [loading, setLoading] = useState(true);
    const [job, setJob] = useState(null);

    const { driver, setDriver } = useContext(DriverContext);

    const Navigate = useNavigate();

    useEffect(() => {
        axios.get(URLS.JOB_GET + "/" + jobId).then((response) => {
            if (!response.data.success) { throw new Error("Failed to get a job"); }

            setJob(response.data.data);
        }).catch(err => console.error(err))
            .finally(() => setLoading(false))
    }, []);

    function HandleJobStart() {
        switch (job.jobType) {
            case 0: Navigate(`/job/${jobId}/start/drop`); break;
            case 1: Navigate(`/job/${jobId}/start/collect`); break;
            case 2: Navigate(`/job/${jobId}/start/exchange`); break;
            default: Navigate(`/job/${jobId}`);
        }
    }

    function HandleDropComplete() { Navigate(`/job/${jobId}/complete/drop`); }

    function HandleCollectCheckin() { Navigate(`/job/${jobId}/checkin/collect`); }

    function HandleCollectADCheckin() { Navigate(`/job/${jobId}/checkin/collect/ad`); }

    function HandleCollectComplete() { Navigate(`/job/${jobId}/complete/collect`); }

    function HandleCollectADComplete() { Navigate(`/job/${jobId}/complete/collect`); }

    function HandleExchangeCheckin() { Navigate(`/job/${jobId}/checkin/exchange`); }

    function HandleExchangeADCheckin() { Navigate(`/job/${jobId}/checkin/exchange/ad`); }

    function HandleExchangeComplete() { Navigate(`/job/${jobId}/complete/exchange`); }

    function HandleExchangeADComplete() { Navigate(`/job/${jobId}/complete/exchange`); }

    function HandleAbort() { Navigate(`/job/${jobId}/abort`); }

    function GetStatus() {
        if (driver?.activeJobId == null && driver?.activeJobId != job?.id) {
            return JobStatus.INACTIVE;
        }
        if (driver?.activeJobId == job?.id && job?.jobType == 0) {
            return JobStatus.ACTIVE_DROP; // Complete Drop off
        }
        if (driver?.activeJobId == job?.id && (job?.jobType == 2 && job?.exchangeId == null)) {
            return JobStatus.ACTIVE_DROP_EXCHANGE; // Checkin Exchange
        }
        if (driver?.activeJobId == job?.id && job?.jobType == 1 && (job?.asset?.state == 3 || job?.asset == null)) {
            return JobStatus.ACTIVE_COLLECTION; // Checkin Collection
        }
        if (driver?.activeJobId == job?.id && job?.jobType == 1 && job?.assetId != null && job?.plantId == 1 && job?.asset?.state != 3) {
            return JobStatus.ACTIVE_COLLECTION_END; // Complete Collection
        }
        if (driver?.activeJobId == job?.id && job?.jobType == 1 && job?.assetId != null && job?.plantId != 1 && job?.asset?.assetState == 4) {
            return JobStatus.ACTIVE_COLLECTION_AD; // Checkin Collection at AD
        }
        if (driver?.activeJobId == job?.id && job?.jobType == 1 && job?.assetId != null && job?.plantId != 1 && job?.asset?.assetState == 5) {
            return JobStatus.ACTIVE_COLLECTION_AD_END; // Complete Collection at AD
        }
        if (driver?.activeJobId == job?.id && (job?.jobType == 2 && job?.exchangeId != null) && job?.plantId == 1) {
            return JobStatus.ACTIVE_EXCHANGE_END; // Complete Exchange
        }
        if (driver?.activeJobId == job?.id && (job?.jobType == 2 && job?.exchangeId != null) && job?.plantId != 1 && job?.exchange?.assetCollected?.assetState != 5) {
            return JobStatus.ACTIVE_EXCHANGE_AD; // Checkin Exchange at AD
        }
        if (driver?.activeJobId == job?.id && (job?.jobType == 2 && job?.exchangeId != null) && job?.plantId != 1 && job?.exchange?.assetCollected?.assetState == 5) {
            return JobStatus.ACTIVE_EXCHANGE_AD_END; // Complete Exchange at AD
        }

        return JobStatus.OTHER_ACTIVE;
    }

    return (
        <>
            <Loading loading={loading} />

            {job != null &&
                <>
                    <div className="w-100 d-flex justify-content-center mt-5">
                        <div className="w-90 mt-5">
                            <JobHeader job={job} />

                            <div className="border shadow rounded mt-3 p-3 bg-white">
                                {GetStatus() == JobStatus.INACTIVE &&
                                    <button className="btn btn-primary w-100 text-white p-3 fs-5" onClick={HandleJobStart}>
                                        Start Job
                                        &nbsp;&nbsp;
                                        <FontAwesomeIcon icon="paper-plane" />
                                    </button>
                                }
                                {GetStatus() == JobStatus.ACTIVE_DROP &&
                                    <button className="btn btn-success w-100 text-white p-3 fs-5" onClick={HandleDropComplete}>
                                        Complete Drop Off
                                        &nbsp;&nbsp;
                                        <FontAwesomeIcon icon="flag-checkered" />
                                    </button>
                                }
                                {GetStatus() == JobStatus.ACTIVE_COLLECTION &&
                                    <button className="btn btn-warning w-100 text-white p-3 fs-5" onClick={HandleCollectCheckin}>
                                        Checkin at Site
                                        &nbsp;&nbsp;
                                        <FontAwesomeIcon icon="truck-loading" />
                                    </button>
                                }
                                {GetStatus() == JobStatus.ACTIVE_COLLECTION_END &&
                                    <button className="btn btn-success w-100 text-white p-3 fs-5" onClick={HandleCollectComplete}>
                                        Complete Collection
                                        &nbsp;&nbsp;
                                        <FontAwesomeIcon icon="flag-checkered" />
                                    </button>
                                }
                                {GetStatus() == JobStatus.ACTIVE_COLLECTION_AD &&
                                    <button className="btn btn-warning w-100 text-white p-3 fs-5" onClick={HandleCollectADCheckin}>
                                        Checkin at AD Plant
                                        &nbsp;&nbsp;
                                        <FontAwesomeIcon icon="industry" />
                                    </button>
                                }
                                {GetStatus() == JobStatus.ACTIVE_COLLECTION_AD_END &&
                                    <button className="btn btn-success w-100 text-white p-3 fs-5" onClick={HandleCollectADComplete}>
                                        Complete AD Tip
                                        &nbsp;&nbsp;
                                        <FontAwesomeIcon icon="flag-checkered" />
                                    </button>
                                }
                                {GetStatus() == JobStatus.ACTIVE_DROP_EXCHANGE &&
                                    <button className="btn btn-warning w-100 text-white p-3 fs-5" onClick={HandleExchangeCheckin}>
                                        Checkin at Site
                                        &nbsp;&nbsp;
                                        <FontAwesomeIcon icon="truck-loading" />
                                    </button>
                                }
                                {GetStatus() == JobStatus.ACTIVE_EXCHANGE_END &&
                                    <button className="btn btn-success w-100 text-white p-3 fs-5" onClick={HandleExchangeComplete}>
                                        Complete Exchange
                                        &nbsp;&nbsp;
                                        <FontAwesomeIcon icon="flag-checkered" />
                                    </button>
                                }
                                {GetStatus() == JobStatus.ACTIVE_EXCHANGE_AD &&
                                    <button className="btn btn-warning w-100 text-white p-3 fs-5" onClick={HandleExchangeADCheckin}>
                                        Checkin at AD Plant
                                        &nbsp;&nbsp;
                                        <FontAwesomeIcon icon="industry" />
                                    </button>
                                }
                                {GetStatus() == JobStatus.ACTIVE_EXCHANGE_AD_END &&
                                    <button className="btn btn-success w-100 text-white p-3 fs-5" onClick={HandleExchangeADComplete}>
                                        Complete Exchange
                                        &nbsp;&nbsp;
                                        <FontAwesomeIcon icon="flag-checkered" />
                                    </button>
                                }
                                {
                                    GetStatus() != JobStatus.OTHER_ACTIVE && GetStatus() != JobStatus.INACTIVE &&
                                    <button className="btn btn-danger w-100 text-white p-3 fs-5 mt-4" onClick={HandleAbort}>
                                        Abort Job&nbsp;&nbsp;<FontAwesomeIcon icon="eject" />
                                    </button>
                                }
                                {GetStatus() == JobStatus.OTHER_ACTIVE &&
                                    <h4 className="mb-0 bg-danger d-flex justify-content-center rounded text-white p-1">Please Complete Active Job First</h4>
                                }
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default JobPage;