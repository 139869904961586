import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
import Loading from "../Components/Universal/Loading";
import { URLS } from "../Consts/URLS";
import axios from "axios";
import { WsContext, DriverContext } from "../Components/Contexts/Main";
import { useNavigate } from "react-router-dom";
import { WebSocketHandlers_INVOKE_EVENTS } from "../Utils/WebSocketHandlers";
import { GetLogin, StoreDriver, StoreLogin } from "../Utils/StorageHandler";
import { CreateAxiosInterceptors } from "../Utils/Setup";

function LoginPage() {
    const [driverCode, setDriverCode] = useState('');
    const [pinCode, setPinCode] = useState('');
    const [loading, setLoading] = useState(false);

    const [verify, setVerify] = useState(false);
    const [code, setCode] = useState('');
    const [token, setToken] = useState('');

    const Navigate = useNavigate();

    const { driver, setDriver } = useContext(DriverContext);
    const WS = useContext(WsContext);

    function HandleLogin(e) {
        e.preventDefault();

        setLoading(true);

        var body = {
            driverCode: driverCode,
            driverPin: pinCode,
        }

        axios.post(URLS.LOGIN, body)
            .then((response) => {
                if (!response.data.success) { throw new Error(response.data.message); }
                if (response.data.data.verify) {
                    setVerify(true);
                    setToken(response.data.data.token);
                } else {
                    var driverObj = response.data.data?.driver;
                    setDriver({ ...driverObj });

                    StoreLogin(response.data.data?.apiKey);
                    StoreDriver(driverObj);

                    CreateAxiosInterceptors();

                    Navigate("jobs");
                    WS.invoke(WebSocketHandlers_INVOKE_EVENTS.InitDriverLogin.name, driverObj?.id);
                }
            })
            .catch((err) => { console.error(err); })
            .finally(() => setLoading(false));
    }

    function Handle2FAVerify(e) {
        e.preventDefault();

        setLoading(true);

        var body = {
            code: code,
            token: token,
        }

        axios.post(URLS.LOGIN + "/validate", body)
            .then((response) => {
                if (!response.data.success) { throw new Error(response.data.message); }

                var driverObj = response.data.data?.driver;
                setDriver({ ...driverObj });

                StoreLogin(response.data.data?.apiKey);
                StoreDriver(driverObj);

                CreateAxiosInterceptors();

                Navigate("jobs");
                WS.invoke(WebSocketHandlers_INVOKE_EVENTS.InitDriverLogin.name, driverObj?.id);
            })
            .catch((err) => { console.error(err); })
            .finally(() => {
                setVerify(false);
                setLoading(false);
            });
    }

    return (
        <>
            <Loading loading={loading} />

            {verify &&
                <div className="modal bg-opacity-50 bg-primary" style={{ display: 'block' }}>
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title  text-dark">2FA Code</h5>
                                <button type="button" class="btn-close" onClick={() => setVerify(false)}></button>
                            </div>
                            <div className="modal-body text-dark">
                                <form>
                                    <label className="ms-1">Enter 2FA Code:</label>
                                    <input required className="form-control" placeholder="Please Enter your 2FA Code..." value={code} onChange={(e) => setCode(e.target.value)} />

                                    <button className="w-100 btn btn-primary mt-3" onClick={Handle2FAVerify}>
                                        Submit
                                        &nbsp;&nbsp;
                                        <FontAwesomeIcon icon="paper-plane" />
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            }

            <div className="mt-5 pt-5 mx-5">
                <div className="mt-5 container border shadow rounded bg-white">
                    <div className="p-3">
                        <div>
                            <form className="" onSubmit={HandleLogin}>
                                <label className="ms-1">Driver Code:</label>
                                <input className="form-control" placeholder="Driver Code..." value={driverCode} onChange={(e) => setDriverCode(e.target.value)} />
                                <label className="mt-2 ms-1">Pin Code:</label>
                                <input className="form-control" placeholder="Pin Code..." value={pinCode} onChange={(e) => setPinCode(e.target.value)} />

                                <button type="submit" className="btn btn-primary w-100 mt-3">
                                    Login... <FontAwesomeIcon icon="sign-in" />
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LoginPage;