import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../../Components/Universal/Loading";
import { DriverContext } from "../../../Components/Contexts/Main";
import axios from "axios";
import { URLS } from "../../../Consts/URLS";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import JobHeader from "../../../Components/Universal/JobHeader";

function CompleteDropPage() {
    const { jobId } = useParams();
    const Navigate = useNavigate();

    const { driver, setDriver } = useContext(DriverContext);

    const [loading, setLoading] = useState(true);
    const [job, setJob] = useState(null);
    const [assetName, setAssetName] = useState('');
    const [error, setError] = useState(false);

    useEffect(() => {
        axios.get(`${URLS.JOB_GET}/${jobId}`).then((response) => {
            if (!response.data.success) { throw new Error("Failed to get a job"); }

            setJob(response.data.data);
            setAssetName(response.data.data?.asset?.assetName);
        }).catch(err => console.error(err))
            .finally(() => setLoading(false))
    }, []);

    function HandleDropComplete(e) {
        e.preventDefault();

        setLoading(true);

        axios.post(`${URLS.JOBS_DROP}/${jobId}/complete?assetName=${assetName}`).then((response) => {
            if (!response.data.success) {
                setError(response.data.message);
                throw new Error("Failed to complete drop job");
            }

            Navigate("/jobs")
        }).catch(err => console.error(err))
            .finally(() => setLoading(false));
    }

    return (
        <>
            <Loading loading={loading} />

            {job != null &&
                <>
                    <div className="w-100 d-flex justify-content-center mt-5">
                        <div className="w-90 mt-5">
                            <JobHeader job={job} />

                            <div className="border rounded shadow mt-3 p-3 bg-white">
                                <form>
                                    <label className="ms-1">Confirm Asset: </label>
                                    <input className="form-control" required placeholder="Confirm Asset Name: (E.g. WF24)" value={assetName} onChange={(e) => setAssetName(e.target.value)}></input>

                                    {error && <p className="my-2 text-danger">{error}</p>}

                                    <button className="btn btn-success w-100 mt-3" onClick={HandleDropComplete} disabled={assetName == ''}>
                                        Complete
                                        &nbsp;&nbsp;
                                        <FontAwesomeIcon icon="flag-checkered" />
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    );
}

export default CompleteDropPage;