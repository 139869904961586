const BASE = "https://idrive.reneco.co.uk/api/toms";

export const URLS = {
    WS: `${BASE}/v1/mobile/driverapphub`,
    LOGIN: `${BASE}/v1/mobile/driver/login`,

    JOBS_GET: `${BASE}/v1/mobile/jobs`,
    JOB_GET: `${BASE}/v1/mobile/job`,
    JOB_ABORT: `${BASE}/v1/mobile/jobs/abort`,
    JOBS_DROP: `${BASE}/v1/mobile/jobs/drop`,
    JOBS_COLLECTION: `${BASE}/v1/mobile/jobs/collection`,
    JOBS_EXCHANGE: `${BASE}/v1/mobile/jobs/exchange`,

    ASSETS_GET: `${BASE}/v1/mobile/assets`,
    PLANTS_GET: `${BASE}/v1/mobile/plants`
}