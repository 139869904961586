import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { DriverContext, JobsContext } from "../../Components/Contexts/Main";
import JobCard from "../../Components/JobCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function JobsList() {
    const { driver, setDriver } = useContext(DriverContext);
    const { jobs, setJobs } = useContext(JobsContext);

    const Navigate = useNavigate();

    return (
        <>
            <div className="mt-2 vh-100">
                <div className="w-100 d-flex justify-content-center align-items-center">
                    <h2 className="bg-white p-2 rounded shadow border-2 border">Job Pool&nbsp;&nbsp;<FontAwesomeIcon icon="stopwatch" /></h2>
                </div>

                {jobs?.filter(j => j.id == driver?.activeJobId)?.map((job, i) => <JobCard key={i} job={job} />)}
                {jobs?.filter(j => j.id != driver?.activeJobId)?.map((job, i) => <JobCard key={i} job={job} />)}

                {jobs?.length == 0 &&
                    <div className="w-100 d-flex justify-content-center mt-5">
                        <div className="w-90 bg-white rounded border-2 border shadow d-flex justify-content-center py-5">
                            <div>
                                <h4 className="text-center mb-1">No Jobs Available</h4>
                                <h6 className="text-center">Please Check Again Later</h6>

                                <div className="w-100 d-flex justify-content-center">
                                    <FontAwesomeIcon icon="shipping-fast" size="2x" />
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div >
        </>
    )
}

export default JobsList;