import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Loading({ loading }) {
    return (
        <>
            {loading &&
                <div className="position-absolute top-0 z-top vh-100 w-100 d-flex justify-content-center align-items-center bg-primary bg-opacity-50">
                    <FontAwesomeIcon spin icon="spinner" size="6x" />
                </div>
            }
        </>
    );
}

export default Loading;