import { useContext, useEffect, useState } from "react";
import { DriverContext } from "../../../Components/Contexts/Main";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { URLS } from "../../../Consts/URLS";
import Loading from "../../../Components/Universal/Loading";
import JobHeader from "../../../Components/Universal/JobHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function ExchangeADCheckin() {
    const { jobId } = useParams();
    const Navigate = useNavigate();

    const { driver, setDriver } = useContext(DriverContext);
    const [job, setJob] = useState(null);

    const [plants, setPlants] = useState(null);

    const [loading, setLoading] = useState(true);

    const [plant, setPlant] = useState(null);

    useEffect(() => {
        axios.get(URLS.JOB_GET + "/" + jobId).then((response) => {
            if (!response.data.success) { throw new Error("Failed to get a job"); }

            setJob(response.data.data);
        }).catch(err => console.error(err));
    }, []);

    useEffect(() => { if (job != null) { SetupOptions(); } }, [job]);

    function SetupOptions() {
        axios.get(URLS.PLANTS_GET).then((response) => {
            if (!response.data.success) { throw new Error("Failed to retrieve Plants"); }

            var _plants = Array.from([...response.data.data], (d) => { return { value: d?.id, label: d?.name } });
            setPlants(_plants);
            setPlant(job?.plantId);
        }).catch(err => console.error(err)).finally(() => setLoading(false));
    }

    function HandleADCheckin(e) {
        e.preventDefault();

        setLoading(true);

        var body = {
            jobId: jobId,
            dropAssetId: job?.exchange?.assetDroppedId,
            collectAssetId: job?.exchange?.assetCollectedId,
            plantId: plant ?? 1
        }

        axios.post(`${URLS.JOBS_EXCHANGE}/checkin/ad`, body).then((response) => {
            if (!response.data.success) { throw new Error("Failed to perform collection checkin"); }

            Navigate("/jobs");
        }).catch(err => console.error(err))
            .finally(() => setLoading(false));
    }

    return (
        <>
            <Loading loading={loading} />

            {job != null &&
                <>
                    <div className="w-100 d-flex justify-content-center mt-5">
                        <div className="w-90 mt-5">
                            <JobHeader job={job} />

                            {plants != null &&
                                <div className="border rounded shadow mt-3 p-3 bg-white">
                                    <form>
                                        <label className="ms-1">Confirm Tipping At: </label>
                                        <select className="form-select" required value={plant} onChange={(e) => setPlant(e.target.value)}>
                                            <option disabled value={null}>Please Select an AD Plant...</option>

                                            {plants.map((plant, i) => (
                                                <option key={i} value={plant.value}>{plant?.label}</option>
                                            ))}
                                        </select>

                                        <button type="submit" className="btn btn-success w-100 mt-3" onClick={HandleADCheckin}>
                                            Checkin at AD Plant
                                            &nbsp;&nbsp;
                                            <FontAwesomeIcon icon="industry" />
                                        </button>
                                    </form>
                                </div>
                            }
                        </div>
                    </div>
                </>
            }
        </>
    );
}

export default ExchangeADCheckin;