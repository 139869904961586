const Keys = {
    ApiKeyStorage: "X-Api-Key",
    DriverKey: "DRIVER-APP.DRIVER"
}

export function StoreLogin(apiKey) {
    localStorage.setItem(Keys.ApiKeyStorage, apiKey);
}

export function HandleStorageLogout() {
    Object.values(Keys).forEach(val => {
        localStorage.removeItem(val);
    });
}

export function GetLogin() {
    return localStorage.getItem(Keys.ApiKeyStorage);
}

export function StoreDriver(driver) {
    localStorage.setItem(Keys.DriverKey, JSON.stringify(driver));
}

export function GetDriver() {
    return JSON.parse(localStorage.getItem(Keys.DriverKey));
}