import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { HandleStorageLogout } from '../../Utils/StorageHandler';
import { useContext, useState } from 'react';
import { DriverContext, JobsContext } from '../Contexts/Main';
import axios from 'axios';

function Header() {
    const Navigate = useNavigate();
    const { driver, setDriver } = useContext(DriverContext);

    const [logout, setLogout] = useState(false);

    let location = useLocation();

    function HandleLogout() {
        HandleStorageLogout();

        axios.interceptors.request.eject();
        axios.interceptors.response.eject();

        setDriver(null);

        Navigate("/");
        setLogout(false);
    }

    return (
        <>
            {logout &&
                <div className="modal bg-opacity-50 bg-primary" style={{ display: 'block' }}>
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title  text-dark">Sign Out</h5>
                                <button type="button" class="btn-close" onClick={() => setLogout(false)}></button>
                            </div>
                            <div className="modal-body text-dark">
                                <h6 className='mb-3 ms-1'>Are you sure you would like to Sign Out?</h6>

                                <div className='row'>
                                    <div className='col'>
                                        <button className='btn btn-danger w-100' onClick={() => setLogout(false)}>Cancel</button>
                                    </div>
                                    <div className='col'>
                                        <button className='btn btn-primary w-100' onClick={HandleLogout}>Sign Out</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }

            <div className="sticky-top w-100 bg-primary p-2 border-bottom border-4 border-success">
                <h1 className={`my-1 d-flex ${location.pathname == "/" ? "justify-content-center" : "justify-content-between"}`}>
                    {location.pathname != "/" &&
                        <span className='d-flex align-items-center mx-2' onClick={() => setLogout(true)}><FontAwesomeIcon icon="sign-out" /></span>}

                    <span className='d-flex align-items-center mx-2'><FontAwesomeIcon icon="crosshairs" />&nbsp;&nbsp;iDrive</span>

                    {location.pathname != "/" &&
                        <span className='d-flex align-items-center mx-2' onClick={() => Navigate("/jobs")}><FontAwesomeIcon icon="home" /></span>}
                </h1>
            </div>

            <Outlet />
        </>
    )
}

export default Header;